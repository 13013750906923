import { push } from "connected-react-router";
import { UsersSectionMode } from "./router.user.goto";

export type OrganizationSection =
  | "general"
  | "bonusPayment"
  | "exportAuthorization"
  | "visitorAuthorization"
  | "hub"
  | "documents"
  | "users";
export type OrganizationGeneralSectionMode =
  | "list"
  | "view"
  | "edit"
  | "editGeneralInformations"
  | "editPaymentInformations"
  | "editExportAuthorizationInformations"
  | "editVisitorInformations"
  | "editHubInformations";
export type BonusPaymentSectionMode = "view";
export type DocumentsSectionMode = "list";
export type PaymentMode = "view" | "edit";

const organizationView = (organizationId: string) =>
  organizationGeneralSection(organizationId, "view");

const organizationEdit = (organizationId: string) =>
  organizationGeneralSection(organizationId, "edit");

const organizationEditGeneralInformations = (organizationId: string) =>
  organizationGeneralSection(organizationId, "editGeneralInformations");

const organizationEditPayementInformations = (organizationId: string) =>
  organizationGeneralSection(organizationId, "editPaymentInformations");

const organizationEditExportAuthorizationInformations = (
  organizationId: string
) =>
  organizationGeneralSection(
    organizationId,
    "editExportAuthorizationInformations"
  );

const organizationEditVisitorInformations = (organizationId: string) =>
  organizationGeneralSection(organizationId, "editVisitorInformations");

const organizationEditHubInformations = (organizationId: string) =>
  organizationGeneralSection(organizationId, "editHubInformations");

const organizationGeneralSection = (
  organizationId: string,
  mode: OrganizationGeneralSectionMode
) => organizationSection(organizationId, "general", mode);

const organizationViewSection = (
  organizationId: string,
  section: OrganizationSection
) => organizationSection(organizationId, section, "view");

const organizationUsersSection = (
  organizationId: string,
  mode: UsersSectionMode,
  userId?: string
) =>
  !!userId
    ? organizationSectionObject(organizationId, "users", mode, userId)
    : organizationSection(organizationId, "users", mode);

const organizationUsersList = (organizationId: string) =>
  organizationUsersSection(organizationId, "list");

const organizationUsersAdd = (organizationId: string) =>
  organizationUsersSection(organizationId, "add");

const organizationUsersEdit = (organizationId: string, userId: string) =>
  organizationUsersSection(organizationId, "edit", userId);

const organizationUsersView = (organizationId: string, userId: string) =>
  organizationUsersSection(organizationId, "view", userId);

const organizationSection = (
  organizationId: string,
  section: OrganizationSection,
  mode: string
) => push(`/organizations/${organizationId}/${section}/${mode}`);

const organizationSectionObject = (
  organizationId: string,
  section: OrganizationSection,
  mode: string,
  objectId: string
) => push(`/organizations/${organizationId}/${section}/${mode}/${objectId}`);

const organizationUsersFilteredList = (
  organizationId: string,
  emailOrFirstnameOrLastname?: string,
  userRole?: string,
  userStatus?: string
) => {
  let filters: string[] = [];
  if (emailOrFirstnameOrLastname)
    filters.push("emailOrFirstnameOrLastname=" + emailOrFirstnameOrLastname);
  if (userRole) filters.push("userRole=" + userRole);
  if (userStatus) filters.push("userStatus=" + userStatus);
  return push(
    `/organizations/${organizationId}/general/view?${filters.join("&")}`
  );
};
const organizationsList = (
  page: number,
  size: number,
  organizationName?: string,
  organizationType?: string
) =>
  push(
    `/organizations?page=${page}&size=${size}${
      organizationName && organizationName !== ""
        ? "&organizationName=" + organizationName
        : ""
    }${
      organizationType && organizationType !== ""
        ? "&organizationType=" + organizationType
        : ""
    }`
  );

const organizationsAdd = () => push(`/organizations/add`);

export const gotoOrganization = {
  organizationSectionObject: organizationSectionObject,
  organizationViewSection: organizationViewSection,

  organizationsList: organizationsList,
  organizationView: organizationView,
  organizationEdit: organizationEdit,
  organizationsAdd: organizationsAdd,
  organizationEditGeneralInformations: organizationEditGeneralInformations,
  organizationEditPayementInformations: organizationEditPayementInformations,
  organizationEditExportAuthorizationInformations:
    organizationEditExportAuthorizationInformations,
  organizationEditVisitorInformations: organizationEditVisitorInformations,
  organizationEditHubInformations: organizationEditHubInformations,

  organizationUsersAdd: organizationUsersAdd,
  organizationUsersEdit: organizationUsersEdit,
  organizationUsersView: organizationUsersView,
  organizationUsersList: organizationUsersList,
  organizationUsersFilteredList: organizationUsersFilteredList,
};
