import { GeoLocation } from "api/geoLocation";
import defaultHubIcon from "components/src/Icons/Hub/default-hub.svg";
import operatorActiveHubIcon from "components/src/Icons/Hub/operator-active-hub.svg";
import operatorInactiveHubIcon from "components/src/Icons/Hub/operator-inactive-hub.svg";
import carrierActiveHubIcon from "components/src/Icons/Hub/carrier-active-hub.svg";
import carrierInactiveHubIcon from "components/src/Icons/Hub/carrier-inactive-hub.svg";
import { FullscreenControl, GeoJSONSourceRaw } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as React from "react";
import ReactMapboxGl, {
  Layer,
  MapContext,
  Popup,
  Source,
} from "react-mapbox-gl";
import { MapEvent } from "react-mapbox-gl/lib/map-events";
import { apiMapboxAccessToken } from "../../../api/configuration";
import { useContext, useLayoutEffect, useState } from "react";

const styles = {
  londonCycle: "mapbox://styles/mapbox/light-v9",
  light: "mapbox://styles/mapbox/light-v9",
  dark: "mapbox://styles/mapbox/dark-v10",
  basic: "mapbox://styles/mapbox/basic-v9",
  outdoor: "mapbox://styles/mapbox/outdoors-v10",
  street: "mapbox://styles/mapbox/streets-v11",
};

const Mapbox = ReactMapboxGl({
  accessToken: apiMapboxAccessToken,
});

const mapStyle = {
  width: "500px",
  height: "282px",
  marginLeft: "30px",
};

interface HeatmapProps {
  onStyleLoad?: (map: any) => any;
  pickupPoints: any;
  deliveryPoints: any;
  southWestBound: GeoLocation;
  northEastBound: GeoLocation;
}

export const Heatmap = (props: HeatmapProps) => {
  const { pickupPoints, deliveryPoints, southWestBound, northEastBound } = props;

  const operatorActiveHubs: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {features: [], type: "FeatureCollection"};
  const operatorInactiveHubs: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {features: [], type: "FeatureCollection"};
  const carrierActiveHubs: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {features: [], type: "FeatureCollection"};
  const carrierInactiveHubs: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {features: [], type: "FeatureCollection"};
  const parsedPickupPoints: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {features: [], type: "FeatureCollection"};
  
  (JSON.parse(pickupPoints) as GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>).features.forEach((pickupPoint: GeoJSON.Feature<GeoJSON.Geometry>) => {
    const type = pickupPoint?.properties?.hubType ?? null
    switch (type) {
      case "OPERATOR_ACTIVE_HUB":
        operatorActiveHubs.features.push(pickupPoint)
        break;
      case "OPERATOR_INACTIVE_HUB":
        operatorInactiveHubs.features.push(pickupPoint)
        break;
      case "CARRIER_ACTIVE_HUB":
        carrierActiveHubs.features.push(pickupPoint)
        break;
      case "CARRIER_INACTIVE_HUB":
        carrierInactiveHubs.features.push(pickupPoint)
        break;
      default:
        parsedPickupPoints.features.push(pickupPoint)
        break;
    }
  });

  const operatorActiveHubsSource: GeoJSONSourceRaw = {
    data: operatorActiveHubs,
    type: "geojson",
  };

  const operatorInactiveHubsSource: GeoJSONSourceRaw = {
    data: operatorInactiveHubs,
    type: "geojson",
  };

  const carrierActiveHubsSource: GeoJSONSourceRaw = {
    data: carrierActiveHubs,
    type: "geojson",
  };

  const carrierInactiveHubsSource: GeoJSONSourceRaw = {
    data: carrierInactiveHubs,
    type: "geojson",
  };

  const pickupPointsSource: GeoJSONSourceRaw = {
    data: parsedPickupPoints,
    type: "geojson",
  };

  const deliveryPointsSource: GeoJSONSourceRaw = {
    data: JSON.parse(deliveryPoints),
    type: "geojson",
  };

  const pickupImg = new Image(25, 25);
  pickupImg.src = defaultHubIcon;

  const operatorActiveHubImg = new Image(25, 25);
  operatorActiveHubImg.src = operatorActiveHubIcon;

  const operatorInactiveHubImg = new Image(25, 25);
  operatorInactiveHubImg.src = operatorInactiveHubIcon;

  const carrierActiveHubImg = new Image(25, 25);
  carrierActiveHubImg.src = carrierActiveHubIcon;

  const carrierInactiveHubImg = new Image(25, 25);
  carrierInactiveHubImg.src = carrierInactiveHubIcon;

  const onStyleLoad: MapEvent = (map) => {
    const { onStyleLoad } = props;

    map.addImage("pickup-point", pickupImg);
    map.addImage("operator-active-hub", operatorActiveHubImg);
    map.addImage("operator-inactive-hub", operatorInactiveHubImg);
    map.addImage("carrier-active-hub", carrierActiveHubImg);
    map.addImage("carrier-inactive-hub", carrierInactiveHubImg);
    map.addControl(new FullscreenControl());

    return onStyleLoad && onStyleLoad(map);
  };

  const heatmapLayer = {
    // increase weight as diameter breast height increases
    "heatmap-weight": {
      property: "nbColis",
      type: "exponential",
      stops: [
        [1, 0],
        [800, 0.8],
        [1000, 1],
      ],
    },
    // assign color values be applied to points depending on their density
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(255,255,178,0)",
      0.02,
      "rgb(0,40,169)",
      0.05,
      "rgb(37,162,204)",
      0.1,
      "rgb(49,210,141)",
      0.2,
      "rgba(177,225,5,0.7)",
      0.4,
      "rgba(253,141,60,0.8)",
      0.6,
      "rgba(240,59,32,0.9)",
      0.8,
      "rgba(189,0,38,1)",
    ],
    "heatmap-radius": {
      stops: [
        [5, 5],
        [7, 15],
        [10, 17],
        [12, 20],
      ],
    },
    "heatmap-intensity": {
      stops: [
        [5, 1],
        [7, 2],
        [10, 4],
        [12, 6],
      ],
    },
  };

  const markerLayer = {
    "circle-radius": {
      property: "nbColis",
      type: "exponential",
      stops: [
        [{ zoom: 22, value: 10 }, 4],
        [{ zoom: 22, value: 20 }, 5],
        [{ zoom: 22, value: 50 }, 6],
        [{ zoom: 22, value: 100 }, 7],
        [{ zoom: 22, value: 300 }, 8],
        [{ zoom: 22, value: 600 }, 9],
        [{ zoom: 22, value: 800 }, 10],
        [{ zoom: 22, value: 1000 }, 12],
      ],
    },
    "circle-color": {
      property: "nbColis",
      type: "exponential",
      stops: [
        [0, "rgba(236,222,239,0)"],
        [10, "rgb(0,40,169)"],
        [20, "rgb(37,162,204)"],
        [50, "rgb(49,210,141)"],
        [100, "rgba(177,225,5,0.7)"],
        [200, "rgba(253,141,60,0.8)"],
        [300, "rgba(240,59,32,0.9)"],
        [600, "rgba(189,0,38,1)"],
      ],
    },
    "circle-stroke-color": "white",
    "circle-stroke-width": 1,
    "circle-opacity": {
      stops: [
        [7, 0],
        [8, 1],
      ],
    },
  };

  return (
    <Mapbox
      style={styles.street}
      fitBounds={[
        [southWestBound.lon, southWestBound.lat],
        [northEastBound.lon, northEastBound.lat],
      ]}
      fitBoundsOptions={{ padding: 15 }}
      containerStyle={mapStyle}
      onStyleLoad={onStyleLoad}
    >
      <Source id="heatmap" geoJsonSource={deliveryPointsSource} />
      <Layer
        id="delivery-marker"
        type="circle"
        sourceId="heatmap"
        paint={markerLayer}
        minZoom={13}
      />
      <Layer
        id="marker"
        type="heatmap"
        sourceId="heatmap"
        paint={heatmapLayer}
        maxZoom={13}
      />
      <Source id="pickup" geoJsonSource={pickupPointsSource} />
      <Layer
        id="pickup-marker"
        type="symbol"
        layout={{ "icon-image": "pickup-point" }}
        sourceId="pickup"
      />
      <Source id="operator-active-hub" geoJsonSource={operatorActiveHubsSource} />
      <Layer
        id="operator-active-hub-marker"
        type="symbol"
        layout={{ "icon-image": "operator-active-hub" }}
        sourceId="operator-active-hub"
      />
      <Source id="operator-inactive-hub" geoJsonSource={operatorInactiveHubsSource} />
      <Layer
        id="operator-inactive-hub-marker"
        type="symbol"
        layout={{ "icon-image": "operator-inactive-hub" }}
        sourceId="operator-inactive-hub"
      />
      <Source id="carrier-active-hub" geoJsonSource={carrierActiveHubsSource} />
      <Layer
        id="carrier-active-hub-marker"
        type="symbol"
        layout={{ "icon-image": "carrier-active-hub" }}
        sourceId="carrier-active-hub"
      />
      <Source id="carrier-inactive-hub" geoJsonSource={carrierInactiveHubsSource} />
      <Layer
        id="carrier-inactive-hub-marker"
        type="symbol"
        layout={{ "icon-image": "carrier-inactive-hub" }}
        sourceId="carrier-inactive-hub"
      />
      {<MarkerPopup />}
    </Mapbox>
  );
};

const getPopupContent = (properties: (GeoJSON.GeoJsonProperties | undefined)): JSX.Element => {
  if (properties?.nbColis) {
    return (
      <p>
        Nombre de colis livrés : {properties?.nbColis ?? 0}
      </p>
    )
  } else if (properties?.hubType) {
    const type = properties?.hubType ?? null
    let name = ""
    switch (type) {
      case "OPERATOR_ACTIVE_HUB":
        name = "Espace de Logistique Urbaine"
        break;
      case "OPERATOR_INACTIVE_HUB":
        name = "Espace de Logistique Urbaine"
        break;
      case "CARRIER_ACTIVE_HUB":
        name = "Entrepôt"
        break;
      case "CARRIER_INACTIVE_HUB":
        name = "Entrepôt"
        break;
    }
    console.log("properties", properties)
    return (
      <p>
        {name}<br/>
        <span style={{color: "grey"}}>{properties?.hubOrganizationName ?? ""}</span><br/>
        {properties?.hubActivityEndDate > 0 ? <b style={{color: "red"}}>INACTIF</b> : <b style={{color: "green"}}>ACTIF</b>}
      </p>
    )
  } else {
    return (
      <p>
        <b style={{fontSize: "14px"}}>Hub détecté automatiquement</b><br/>
        <i>(plus de 100 colis ayant transités par ce point)</i>
      </p>
    )
  }
}

const MarkerPopup = () => {
  const [selectedMarker, setSelectedMarker] = useState<mapboxgl.MapLayerMouseEvent|null>(null);
  const map = useContext(MapContext);

  useLayoutEffect(() => {
    ["delivery-marker", "operator-active-hub-marker", "operator-inactive-hub-marker", 
    "carrier-active-hub-marker", "carrier-inactive-hub-marker", "pickup-marker"].forEach((id)=>{
      map?.on("click", id, (event) => {
        setSelectedMarker(null);
        setSelectedMarker(event);
      });
    })
  }, [map]);

  return (
    selectedMarker && (
      <Popup
        coordinates={selectedMarker.lngLat}
        onClick={() => {
          setSelectedMarker(null);
        }}
      >
        {getPopupContent(selectedMarker.features?.at(0)?.properties)}
      </Popup>
    )
  );
};
