import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface HubRemovedEvent extends city.colisactiv.organization.model.features.HubRemovedEvent {}

export const removeHub = (
  organizationId: String,
  hubId: String,
): Promise<HubRemovedEvent | null> => {
  return http.get(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/hub/${hubId}/remove`
    ), "Une Erreur est survenue"
  )
};
