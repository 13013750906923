import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface HubActivationToggledEvent extends city.colisactiv.organization.model.features.HubActivationToggledEvent {}

export const toggleHubActivation = (
  organizationId: String,
  hubId: String,
): Promise<HubActivationToggledEvent | null> => {
  return http.get(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/hub/${hubId}/toggleActivation`
    ), "Une Erreur est survenue"
  );
};
