import { connect } from "react-redux";
import { global } from "store/global";
import { State } from "../../../../../store";
import { router } from "../../../../../store/router";
import { organization } from "../../../../../store/organization";

const mapStateToProps = (state: State) => ({
  organizationRefs: global.selectors.organizationRefs(state),
  carrierRefs: global.selectors.carrierRefs(state),
  operatorRefs: global.selectors.operatorRefs(state),
  organization: organization.selectors.organization(state),
});

const mapDispatchToProps = {
  gotoOrganizationEditGeneralInformations:
    router.goto.organization.organizationEditGeneralInformations,
  gotoOrganizationEditPaymentInformations:
    router.goto.organization.organizationEditPayementInformations,
  gotoOrganizationEditExportAuthorizationInformations:
    router.goto.organization.organizationEditExportAuthorizationInformations,
  gotoOrganizationEditVisitorInformations:
    router.goto.organization.organizationEditVisitorInformations,
  gotoOrganizationEditHubInformations:
    router.goto.organization.organizationEditHubInformations,
  gotoOrganizationsView: router.goto.organization.organizationView,
  updateOrganization: organization.commands.updateOrganization,
  deleteOrganization: organization.commands.deleteOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps);
