import { Box } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, DatePicker, TextField } from "components";
import { useTranslation } from "react-i18next";
import { AddressAutocomplete } from "../AddressAutocomplete";
import { Address } from "api/organization";
import { AddHubCommandDTO } from "api/organization/command/addHubCommand";
import { Territory } from "api/geoZone";
import { TerritoryAutocomplete } from "../TerritoryAutocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from 'date-fns/locale/fr';

const useStyles = makeStyles(() => ({
  box: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "start",
    alignItems: "stretch",
    width: "550px !important",
  },
  inputForm: {
    width: "100%",
  },
  pickerInput: {
    paddingRight: "10px",
    width: "100%",
    '& .MuiInputBase-root': {
      maxHeight: "50px",
    }
  }
}));

interface HubFormProps {
  addHub: (command: AddHubCommandDTO) => void;
  readonly: boolean;
}

export const HubForm = (
  props: HubFormProps
) => {
  const { addHub, readonly } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [address, setAddress] = useState<Address|undefined>(undefined);
  const [activityStartDate, setActivityStartDate] = useState<Date|undefined>(undefined);
  const [territories, setTerritories] = useState<Territory[]>([]);

  const onAddressChange = (address: Address) => {
    setAddress(address)
  };
  const onActivityStartDateChange = (date: Date) => {
    setActivityStartDate(date)
  };
  const onTerritoryChange = (territories: Territory[]) => {
    setTerritories(territories)
  };
  const validForm = useCallback(() => {
    return address != undefined && activityStartDate != undefined
  },[address, activityStartDate]);

  const add = () => {
    if (!validForm()) return
    addHub({
      address: address!!,
      activityStartDate: activityStartDate!!.getTime(),
      territories: territories
    })
    setAddress(undefined)
    setActivityStartDate(undefined)
    setTerritories([])
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.box} style={{flexDirection: 'column'}}>
        <AddressAutocomplete
          onChange={onAddressChange}
          value={address}
          label={t("app_organizations_hub_address")}
          className={classes.inputForm}
          disabled={readonly}
          style={{lineHeight: 1}}
        />
        <Box className={classes.box}>
          <TextField 
          label={t("app_organizations_postal_code")}
          value={address?.zipCode} 
          disabled
          style={{paddingRight: "10px", lineHeight: 1}}/>
          <TextField 
          label={t("app_organizations_hub_city")} 
          value={address?.city} 
          disabled 
          style={{lineHeight: 1}}/>
        </Box>
      </Box>
      <Box className={classes.box}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          <DatePicker
            id="organization-hub-form-activity-start-date"
            dateLabel={t("app_organizations_hub_activity_start_date")}
            onChangeDate={onActivityStartDateChange}
            value={activityStartDate}
            className={classes.pickerInput}
            clearable
            disabled={readonly}
          />
        </MuiPickersUtilsProvider>
        <TerritoryAutocomplete
          onChange={onTerritoryChange}
          value={territories}
          multiple
          label={t("app_organizations_hub_territories")}
          className={classes.inputForm}
          disabled={readonly}
          style={{lineHeight: 1}}
        />
      </Box>
      <Box className={classes.box}>
        <Button
          onClick={add}
          disabled={readonly || !validForm()}
        >
          {t('app_organizations_hub_add')}
        </Button>
      </Box>
    </Box>
  );
};
