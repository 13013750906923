import { city as cityOrga } from "ca-plateform-organization-domain";
import { city as cityOrgRef } from "ca-plateform-organization-ref-domain";
import { createOrganization, updateOrganization } from "./service";
import { createApplication } from "./command/createApplication";
import { city as cityPlat } from "ca-plateform-plateform-commons";
import { fetchOrganizationsPage } from "./query/getOrganizationsPageQuery";
import { updatePaymentInfo } from "./command/updatePaymentInfo";
import { getSecret } from "./query/getSecret";
import { updateApplicationSecret } from "./command/updateSecret";
import { getOrganization } from "./query/getOrganizationQuery";
import { fetchDocuSignUrl } from "./query/fetchDocuSignUrl";
import { fetchOrganizationsReferences } from "./query/getOrganizationsReferences";
import { deleteOrganizationCommand } from "./command/deleteOrganizationCommand";
import { prepareSignConvention } from "./command/prepareSignConvention";
import { fetchConventionFile } from "./query/fetchConventionFile";
import { fetchConvention } from "./query/fetchConvention";
import { resetConvention } from "./command/resetConvention";
import { exportOrganizationsDolibarr } from "./query/exportOrganizationsDolibarr";
import { updateExportAuthorization } from "./command/updateExportAuthorization";
import { VisitorAuthorization, updateVisitorAuthorization } from "./command/updateVisitorAuthorization";
import { fetchGeoApiAddress } from "./query/fetchGeoApiAddress";
import { addHub } from "./command/addHubCommand";
import { removeHub } from "./command/removeHubCommand";
import { Territory } from "components/src/Domain/GeoZone";
import { toggleHubActivation } from "./command/toggleHubActivationCommand";

export interface OrganizationPage
  extends cityPlat.colisactiv.platform.commons.Page<Organization> {}

export interface Alert extends cityPlat.colisactiv.platform.commons.Alert<{}> {}

export type OrganizationId = String
export type OrganizationFriendlyId = String
export interface Organization
  extends cityOrga.colisactiv.organization.model.Organization {
    id: OrganizationId
    friendlyId: OrganizationFriendlyId
    organizationType: OrganizationType
    details: OrganizationDetails
    date: String
    payment?: PaymentDetails
    applications: Application[]
    status?: OrganizationStatus
    hasLogo: Boolean
    convention?: Convention
    packageType?: PackageType
    exportAuthorization?: ExportAuthorization
    territories?: Territory[]
    visitorAuthorization?: VisitorAuthorization
    hubs?: Hub[]
  }

export interface OrganizationRef
  extends cityOrgRef.colisactiv.plateform.organization.ref.model
    .OrganizationRef {
  profileImgUrl: string;
}

export interface Application
  extends cityOrga.colisactiv.organization.model.Application {}

export interface OrganizationStatus
  extends cityOrga.colisactiv.organization.model.OrganizationStatus {}

export interface OrganizationDetails
  extends cityOrga.colisactiv.organization.model.OrganizationDetails {}

export interface OrganizationType
  extends cityOrga.colisactiv.organization.model.OrganizationType {}

export interface PackageType
  extends cityOrga.colisactiv.organization.model.PackageType {}

export interface Address extends cityOrga.colisactiv.organization.model.Address {}

export type HubId = String

export interface Hub extends cityOrga.colisactiv.organization.model.Hub {}

export interface ExportAuthorization
  extends cityOrga.colisactiv.organization.model.ExportAuthorization {}

export const OrganizationTypeValues =
  new cityOrga.colisactiv.organization.model.OrganizationTypeValues();

export interface PaymentDetails
  extends cityOrga.colisactiv.organization.model.PaymentDetails {}

export interface Convention
  extends cityOrga.colisactiv.organization.model.Convention {}

export interface ConventionStatus
  extends cityOrga.colisactiv.organization.model.ConventionStatus {}

export interface VisitorAuthorization
  extends cityOrga.colisactiv.organization.model.VisitorAuthorization {}

export interface VisitorRole
  extends cityOrga.colisactiv.organization.model.user.VisitorRole {}

export interface VisitorRoleBase
  extends cityOrga.colisactiv.organization.model.user.VisitorRoleBase {}

export const VisitorRoleValues =
  new cityOrga.colisactiv.organization.model.user.VisitorRoleValues();

export const OrganizationStateMachine =
  new cityOrga.colisactiv.organization.model.OrganizationStateMachine();
export interface ConventionActions
  extends cityOrga.colisactiv.organization.model.ConventionActions {}
export const ConventionStatusValues =
  new cityOrga.colisactiv.organization.model.ConventionStatusValues();
export const ConventionStateMachine =
  new cityOrga.colisactiv.organization.model.ConventionStateMachine();

export const organizationClient = {
  createOrganization: createOrganization,
  updateOrganization: updateOrganization,
  updatePaymentInfo: updatePaymentInfo,
  addHub: addHub,
  toggleHubActivation: toggleHubActivation,
  removeHub: removeHub,
  updateExportAuthorization: updateExportAuthorization,
  updateVisitorAuthorization: updateVisitorAuthorization,
  createApplication: createApplication,
  updateApplicationSecret: updateApplicationSecret,
  prepareSignConvention: prepareSignConvention,
  resetConvention: resetConvention,
  deleteOrganizationCommand: deleteOrganizationCommand,
  query: {
    getOrganization: getOrganization,
    fetchOrganizationsPage: fetchOrganizationsPage,
    fetchOrganizationRefs: fetchOrganizationsReferences,
    getSecret: getSecret,
    fetchConventionFile: fetchConventionFile,
    fetchConvention: fetchConvention,
    fetchDocuSignUrl: fetchDocuSignUrl,
    fetchGeoApiAddress: fetchGeoApiAddress,
    exportOrganizationsDolibarr: exportOrganizationsDolibarr,
  },
};
