import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface AddHubCommandDTO extends city.colisactiv.organization.model.features.AddHubCommandDTO {}

export interface HubAddedEvent extends city.colisactiv.organization.model.features.HubAddedEvent {}

export const addHub = (
  organizationId: String,
  command: AddHubCommandDTO
): Promise<HubAddedEvent | null> => {
  return http.put(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/hub/add`
    ),
    JSON.stringify(command)
  );
};
