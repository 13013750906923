import React, { useCallback, useContext, useState } from 'react'
import { FormControlLabel, makeStyles } from '@material-ui/core'
import { themeContext, Theme } from '@smartb/archetypes-ui-components'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { AutoComplete } from 'components'
import { Territory, geoZoneClient } from 'api/geoZone'

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    root: {
      borderRadius: 20
    },
    container: {
      marginLeft: '0px',
      marginRight: '0px',
      '& .MuiTypography-root': {
        fontSize: '14px'
      },
      '& .MuiButtonBase-root:hover': {
        background: 'transparent'
      }
    },
    icon: {
      color: theme.primaryColor
    },
    iconSize: {
      width: '22px',
      height: '22px'
    }
  }))

interface TerritoryAutocompleteProps {
  disabled?: boolean
  onChange: (territories: Territory[]) => void
  value?: Territory[]
  label?: string
  multiple?: boolean
  className?: string
  style?: CSSProperties
}

export const TerritoryAutocomplete = (props: TerritoryAutocompleteProps) => {
  const {
    disabled = false,
    onChange,
    value = undefined,
    multiple = false,
    label = multiple ? 'Territoires' : 'Territoire',
    className,
    style
  } = props
  const theme = useContext(themeContext)
  const classes = useStyles(theme)()

  const handleTerritoryChange = useCallback(
    (value: Territory | Territory[]) => {
      const territories = Array.isArray(value) ? value : [value]
      onChange(territories)
    },
    [onChange]
  )

  const [autocompleteText, setAutocompleteText] = useState(
    'Rechercher un territoire'
  )
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Territory[]>(
    []
  )

  const handleSearchTerritory = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setAutoCompleteOptions([])
      setAutocompleteText('Rechercher un territoire')
      return
    }

    try {
      const response = await geoZoneClient.query.getTerritories(
        0,
        5,
        search.trim()
      );
      setAutoCompleteOptions(response.list)
      if (response.list.length === 0) {
        setAutocompleteText('Aucun territoire trouvé')
      }
    } catch (err) {
      console.error(err)
      setAutoCompleteOptions([])
      setAutocompleteText('Une erreur est survenue, veuillez réessayer')
    }
  }, [])

  const getTerritoryName = useCallback((territory: Territory) => {
    return territory.name
  }, [])

  return (
    <FormControlLabel
      value={value}
      className={`${classes.container} ${className}`}
      style={style}
      control={
        <AutoComplete<Territory>
          id='territory-select'
          onChangeSelectedElement={handleTerritoryChange}
          options={autoCompleteOptions}
          defaultValue={value}
          label={label}
          multiple={multiple}
          onSearch={handleSearchTerritory}
          noOptionsText={autocompleteText}
          getOptionLabel={getTerritoryName}
          placeholder=''
          readonly={disabled}
          style={{width: '100%'}}
        />
      }
      label={''}
    />
  )
}
