import { makeStyles } from "@material-ui/core";
import { Organization } from "api/organization";
import { User } from "api/organization/users";
import { HeadBarProps, SectionForm } from "components";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { nullToUndefined } from "utils";
import { routesAccessRights } from "../../../../auth/RoutesAccessRightsMap";
import { useAuth } from "../../../../auth/useAuth";
import OrganizationDocuments from "./Documents";
import GeneralInformations from "./OrganizationForm";
import OrganizationUsersPage from "./Users/List";
import withConnect from "./withConnect";

const useStyles = makeStyles(() => ({
  button: {
    position: "absolute",
    right: "30px",
  },
  title: {
    borderBottom: "2px solid #98a5ab33",
    padding: "20px 0",
    marginBottom: "30px",
  },
  text: {
    color: "#353945",
    fontWeight: 600,
    fontSize: "19px",
    lineHeight: "23px",
  },
}));

interface OrganizationProfileProps {
  organization: Organization | null;
  fetchOrganization: (organizationId: string) => void;
  gotoOrganizationUser: (organizationId: string) => void;
  setHeadBar: (title: HeadBarProps) => void;
  currentUser: User | null;
  gotoOrganizationView: (organizationId: string) => void;
}

const OrganizationProfile = (props: OrganizationProfileProps) => {
  const {
    organization,
    fetchOrganization,
    setHeadBar,
    currentUser,
    gotoOrganizationView,
  } = props;
  const classes = useStyles();
  const { organizationId } = useParams();
  const [authService, keycloak] = useAuth();
  const [docusignEvent, setDocusignEvent] = useState<string | undefined>(
    undefined
  );
  const { t } = useTranslation();

  useEffect(() => {
    const authResult = new URLSearchParams(window.location.search);
    const event = nullToUndefined(authResult.get("event"));
    setDocusignEvent(event);
    if (event && event !== "") gotoOrganizationView(organizationId);
  }, []);

  useEffect(() => {
    fetchOrganization(organizationId);
  }, [organizationId, fetchOrganization]);

  useEffect(() => {
    organization &&
      setHeadBar({
        title: organization.details.name,
      });
  }, [organization, setHeadBar]);

  const shouldShowDocument = (
    organization: Organization | null,
    currentUser: User | null
  ): boolean => {
    const operatorOrganization =
      organization && organization.organizationType.type === "Operator";
    const isAuthorizedUser =
      currentUser &&
      authService.hasRoles(
        currentUser,
        ["admin", "user", "operator_admin", "operator_admin_onboarding"],
        keycloak
      );
    return (
      operatorOrganization !== null &&
      operatorOrganization &&
      isAuthorizedUser !== null &&
      isAuthorizedUser
    );
  };

  const isOrganizationAdmin = useMemo(
    () => authService.isOrganizationAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );

  const isColisActivAdmin = useMemo(
    () => authService.isColisActivAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );

  return (
    <SectionForm withGoBack>
      <GeneralInformations
        isOrganizationAdmin={isOrganizationAdmin}
        isColisActivAdmin={isColisActivAdmin}
        fetchOrganization={() => fetchOrganization(organizationId)}
      />

      {authService.isAuthorized(
        routesAccessRights["/organizations/documents"],
        keycloak
      ) &&
        shouldShowDocument(organization, currentUser) && (
          <>
            <div className={classes.title}>
              <span className={classes.text}>
                {" "}
                {t("app_organizations_profil_users_documents")}{" "}
              </span>
            </div>
            <OrganizationDocuments docusignEvent={docusignEvent} />
          </>
        )}
      <div className={classes.title}>
        <span className={classes.text}>
          {" "}
          {t("app_organizations_profil_users_users")}{" "}
        </span>
      </div>
      <OrganizationUsersPage />
    </SectionForm>
  );
};

export default withConnect(OrganizationProfile);
