import { TextField, Chip, FormControl, makeStyles, Typography } from '@material-ui/core'
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'
import React, { useCallback, useContext } from 'react'
import { themeContext } from '@smartb/archetypes-ui-components'
import { useInputStyles } from '../../Theme'

const useStyles = makeStyles(() => ({
  padding: {
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: 0,
      paddingRight: '27px !important'
    },
    '& .MuiChip-root': {
      backgroundColor: '#EBEBEC !important',
      borderRadius: '5px !important',
      border: 'none'
    }
  }
}))

interface AutoCompleteProps<T> {
  id: string
  className?: string
  style?: React.CSSProperties
  multiple?: boolean
  options: T[]
  onChangeSelectedElement?: (value: T | T[]) => void
  label: string
  defaultValue?: T | T[]
  onSearch: (value: string) => void
  noOptionsText: string
  textFieldClassName?: string
  getOptionLabel: (option: T) => string
  getSelectedOptionLabel?: (option: T) => string
  readonly?: boolean
  error?: boolean
  errorMessage?: string
  placeholder?: string
  helperTextClassName?: string
  getOptionSelected?: (option: T, value: T) => boolean
}

export function AutoComplete<T>(props: AutoCompleteProps<T>) {
  const {
    className,
    style,
    multiple = false,
    id,
    options,
    onChangeSelectedElement,
    label,
    defaultValue = null,
    onSearch,
    noOptionsText,
    textFieldClassName,
    getOptionLabel,
    getSelectedOptionLabel,
    readonly = false,
    error = false,
    errorMessage = '',
    placeholder,
    helperTextClassName,
    getOptionSelected
  } = props

  const theme = useContext(themeContext)
  const classes = useInputStyles(theme, readonly)()
  const classesLocal = useStyles()

  const onChange = useCallback(
    (event, newValue) =>
      onChangeSelectedElement && onChangeSelectedElement(newValue || []),
    [onChangeSelectedElement]
  )

  const renderOption = useCallback(
    (value: T) => <Typography>{getOptionLabel(value)}</Typography>,
    [getOptionLabel]
  )

  const renderTags = useCallback(
    (value: T[], getTagProps) =>
      value.map((option: T, index: number) => (
        <Chip label={getOptionLabel(option)} {...getTagProps({ index })} />
      )),
    [getOptionLabel]
  )

  const renderInput = useCallback(
    (params) => (
      <TextField
        error={error}
        style={{
          marginTop: '-3px !important',
          height: 'auto'
        }}
        placeholder={placeholder ? placeholder : ''}
        helperText={error ? errorMessage : ''}
        {...params}
        variant={'filled'}
        className={`${classesLocal.padding} ${textFieldClassName} ${classes.input}`}
        onChange={(value) => {
          onSearch(value.target.value)
        }}
        FormHelperTextProps={{
          className: helperTextClassName
        }}
      />
    ),
    [
      error,
      placeholder,
      errorMessage,
      textFieldClassName,
      onSearch,
      classesLocal,
      classes
    ]
  )

  return (
    <FormControl variant='filled' style={style}>
      {label ? <div className={classes.label}>{label}</div> : null}
      <MuiAutocomplete
        id={id}
        filterSelectedOptions
        multiple={multiple}
        options={options}
        className={className}
        value={defaultValue}
        defaultValue={defaultValue}
        forcePopupIcon={false}
        getOptionLabel={getSelectedOptionLabel ? getSelectedOptionLabel : getOptionLabel}
        style={{ ...style }}
        disabled={readonly !== undefined ? readonly : false}
        noOptionsText={noOptionsText}
        onChange={onChange}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={renderInput}
        getOptionSelected={getOptionSelected}
      />
    </FormControl>
  )
}
